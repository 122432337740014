<template>
  <div>Главная</div>
</template>

<script>
export default {
  name: "Main",
};
</script>

<style scoped></style>
